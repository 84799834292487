<template>
  <main>
    <content-page-header>
      <common-page-title title="パスワードリセット" />
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <section>
          <common-block-title
            title="パスワード変更完了"
            sub-title=""
          />
          <div class="complete-message">
            <p class="complete-message-text">
              パスワードの変更が完了しました。<br />
              下のボタンをクリックしてトップページに戻り、ログインをお願いいたします。
            </p>
          </div>
        </section>
        <div class="submit-area">
          <button
            class="submit-area-button submit-area-button--save"
            @click="$router.push('/')"
          >
            TOPページへ
          </button>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive } from "vue";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
export default defineComponent({
  name: "resetPasswordComplete",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
    CommonBlockTitle,
  },
  props: [],
  setup() {
    const state = reactive({});
    window.scrollTo(0, 0);
    return {
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.content-body {
  position: relative;
  margin-top: -100px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.complete-message {
  padding: 65px 0;
  border-bottom: 0.5px solid #0f0f11;
}
.complete-message-text {
  line-height: 1.7;
}

</style>